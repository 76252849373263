// Here you can add other styles


.toast-success{

  color: forestgreen !important;

}
.toast-error{

  color: $primary !important;

}
.toast-info{


}
